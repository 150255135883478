<template>
  <van-action-sheet  :actions="actions" @select="onChange"/>
</template>
<script>
import { postCommon } from '@/api/common'

// v-model:show="visable"
export default {
  name: 'ActionSheet',
  props: {
    serverUrl: {
      type: String,
      required: false,
      default: null
    },
    visable: {
      type: Boolean,
      required: false,
      default: false
    },
    actions: {
      type: Array,
      required: false,
      default: null
    },
    sName: {
      type: String,
      required: false,
      default: null
    },
    sValue: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      // actions: [],
    }
  },
  created() {

  },
  mounted() {
    this.loadValues()
  },
  methods: {
    loadValues() {
      if (this.serverUrl) {
        console.log('sValue', this.sValue, this.sName)
        postCommon(this.serverUrl, {}).then(res => {
          const data = res.body.content
          this.actions = data.map(item => {
            return {
              name: item[this.sName],
              value: item[this.sValue]
            }
          })
        })
      } else {
        this.actions = []
      }
    },
    onChange(item) {
      console.log('change:', item)
      this.$emit('change', item)
    }

  }
}
</script>

<style lang="less">
</style>

