import request from '@/utils/request'

export function pushLog(data) {
  return request({
    url: '/api/app/log',
    method: 'put',
    data
  })
}

// 通用请求
export function postCommon(url, params) {
  return request({
    url: url,
    method: 'post',
    data: params
  })
}

// 通用Body
export function postBody(url, data) {
  return request({
    url: url,
    method: 'put',
    data: data
  })
}
