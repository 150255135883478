<template>
  <van-nav-bar
    :title="name"
    :left-text="leftText"
    :right-text="right"
    :left-arrow="leftArrow"
    @click-left="goBack"
    @click-right="rightClick"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    // leftarrow: {
    //   type: Boolean,
    //   default: false
    // },
    // left_arrow: {
    //   type: Boolean,
    //   default: false
    // },
    leftArrow: {
      type: Boolean,
      default: false
    },
    leftText: {
      type: String,
      default: ''
    },
    right: {
      type: String,
      default: ''
    },
    back: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack() {
      if (!this.back) {
        this.$router.go(-1)
      } else {
        this.$router.push({ path: this.back })
      }
      this.$emit('callback')
    },
    rightClick() {
      this.$emit('rightClick')
    }
  }
}
</script>

<style lang="less" scoped>
  // @import '../../common/style/mixin';
  // .simple-header {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 10000;
  //   .fj();
  //   .wh(100%, 44px);
  //   line-height: 44px;
  //   padding: 0 10px;
  //   .boxSizing();
  //   color: #252525;
  //   background: #fff;
  //   border-bottom: 1px solid #dcdcdc;
  //   .simple-header-name {
  //     font-size: 14px;
  //   }
  // }
</style>
