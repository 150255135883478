<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
@import 'assets/static/css/css.css';
@import 'assets/static/css/icon.css';



#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.van-doc-demo-block__title {
  margin: 0;
  padding: 12px 12px 12px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.van-doc-demo-block__card {
  margin: 12px 12px 0;
  overflow: hidden;
  border-radius: 8px;
}
.van-doc-demo-block__title + .van-doc-demo-block__card {
  margin-top: 0;
}
.van-doc-demo-block:first-of-type .van-doc-demo-block__title {
  padding-top: 20px;
}
</style>
