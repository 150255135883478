module.exports = {
  appId: 'lingrui',
  appName: '铃瑞固定资产平台',
  title: '铃瑞固定资产平台',
  cdnServerUrl: 'https://jmcglr.oss-cn-hangzhou.aliyuncs.com/',
  logoUrl: 'https://jmcglr.oss-cn-hangzhou.aliyuncs.com/static/platform/img/logo.png',
  edu: false,
  mpAppId: 'wx4e8dfb0373b1abd6',
  // appServerUrl: 'http://127.0.0.1:86/',
  // appServerUrl: 'https://assets.jmcglr.com/',
  appServerUrl: '//assets.jmcglr.com/',
  //
  authServerUrl: 'http://wx.ws168.cc/redirect?redirectUrl=',
  errorLog: 'production'
}
